<si-side-panel #sidePanel [collapsed]="collapsed" [mode]="mode" [size]="size" (closed)="collapsed = true">
  <div class="container">
    <section>
      <si-loading-spinner *ngIf="isLoading"></si-loading-spinner>
      <div class="container">
        <div class="row" *ngIf="!isLoading" [hidden]="hideProjectsPanel">
          <div class="col-md p-5 h-100">
            <div class="row p-5">
              <div class="col col-sm-6 center-items">
                <h2>Your Projects</h2>
              </div>
              <div class="col col-sm-6 right-items">
                <button type="button" class="btn btn-primary me-6" (click)="createProject(template)">Create Project</button>
              </div>
            </div>
            <div class="mb-4 col-12">
              <div class="position-relative">
                <i class="search-bar-icon icon element-search text-secondary ng-star-inserted"></i>
                <input type="text" class="form-control ps-9 icon-start"
                  placeholder="Search by Name, BU or Subscription Id" (keyup)="updateFilter($event)" />
              </div>
            </div>
            <ngx-datatable #table class="table-element elevation-1" [cssClasses]="tableConfig.cssClasses" [rows]="projects"
              [rowHeight]="tableConfig.rowHeight" [columns]="columns" [columnMode]="ColumnMode.force"
              [headerHeight]="tableConfig.headerHeight" [footerHeight]="0" [ghostLoadingIndicator]="isLoading"
              [selectionType]="selectionType" [externalPaging]="false" [limit]="10" [offset]="pageNumber"
              [count]="projects.length" [virtualization]="false" [scrollbarV]="false" (select)="setProject($event.selected)"
              siDatatableInteraction>
              <ngx-datatable-column name="Name" [minWidth]="100" [resizeable]="true" [canAutoResize]="true" [sortable]="false">
                <ng-template let-row="row" let-value="value" ngx-datatable-cell-template>
                  <div *ngIf="row.ancestorProjects.length > 0" class="ml-50">{{value}}</div>
                  <div *ngIf="row.ancestorProjects.length <= 0">{{value}}</div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column prop="businessUnit" name="Business Unit" [minWidth]="40" [resizeable]="true" [canAutoResize]="true" [sortable]="false">
              </ngx-datatable-column>
              <ngx-datatable-column prop="numSubscriptions" name="Subscriptions" [minWidth]="10" [resizeable]="true" [canAutoResize]="true" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div *ngIf="row.isFolder"></div>
                  <div *ngIf="row.subscriptionCount > 0">{{row.subscriptionCount}}</div>
                </ng-template>
              </ngx-datatable-column>
              <ngx-datatable-column name="Is Folder" [minWidth]="10" [resizeable]="false" [canAutoResize]="false" [sortable]="false">
                <ng-template let-row="row" ngx-datatable-cell-template>
                  <div *ngIf="row.isFolder" class="icon element-folder"></div>
                  <div *ngIf="row.ancestorProjects.length > 0" class="icon element-up-4"></div>
                </ng-template>
              </ngx-datatable-column>
              <si-empty-state empty-content icon="element-users" heading="No projects to display"></si-empty-state>
            </ngx-datatable>
            <si-pagination class="ms-auto mt-8" [currentPage]="pageNumber + 1" [totalRowCount]="projects.length"
              [pageSize]="pageSize" forwardButtonText="forward" backButtonText="back"
              (currentPageChange)="pageNumber = $event - 1">
            </si-pagination>

          </div>
        </div>
        <div class="row">
          <div class="p-5 clearfix si-layout-fixed-height h-100" [hidden]="hideWelcomeNoProjectsPanel">
            <div class="container">
              <div class="row">
                <div class="col col-md-9">
                  <h2 *ngIf="currentUser !== undefined">Welcome {{currentUser.name}} to the My Cloud Security User Self
                    Service</h2>
                  <h2 *ngIf="currentUser === undefined">Welcome to the My Cloud Security User Self Service</h2>
                  <p>The project management is allowed only for the Technical Responsible or the Service Owner of a
                    cloud account.</p>
                </div>
              </div>
            </div>

          </div>
        </div>
        <div class="row">
          <div class="p-5 clearfix si-layout-fixed-height h-100" [hidden]="hideErrorPanel">
            <div class="container">
              <div class="row">
                <div class="col col-md-9">
                  <h2 *ngIf="currentUser !== undefined">Welcome {{currentUser.name}} to the My Cloud Security User Self
                    Service</h2>
                  <h2 *ngIf="currentUser === undefined">Welcome to the My Cloud Security User Self Service</h2>
                  <p>The User Self Service Portal is currently Unavailable. Apologies for the inconvenience.</p>
                  <p><a href="https://wiz.io/">Wiz Dashboard</a> | <a href="https://clp.siemens.io/cnapp-service/">CNAPP Wiki</a> | <a href="https://web.yammer.com/main/groups/eyJfdHlwZSI6Ikdyb3VwIiwiaWQiOiIxMzI4MTA4MjU3MjgifQ">Viva Engage</a></p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </section>
  </div>

  <si-side-panel-content class="p-5" *ngIf="selectedProject !== undefined" heading="{{selectedProject.name}}">
    <div class="p-5">        
      <button *ngIf="!isSelectedProjectAPersonalProject" type="button" (click)="editProject(template)"
      class="btn btn-primary me-2">Edit Project</button>

      <button type="submit" class="btn btn-primary me-2" (click)="setAccessReview()">Confirm Access Review
      </button>

      <button *ngIf="!isSelectedProjectAPersonalProject" type="button" (click)="confirmProjectArchiving()"
      class="btn btn-danger me-2">Archive Project</button>
    </div>

    <si-tabset [(selectedTabIndex)]="selectedTabIndex">
      <si-tab heading="Project">
        <si-form-item label="Project Name" [labelWidth]="labelWidthDummy" >
          <input type="text" id="dummyName" class="form-control" [ngModel]="this.selectedProject!.name"  disabled/>
        </si-form-item>

        <si-form-item label="Project Id" [labelWidth]="labelWidthDummy">
            <input  type="text" id="dummyId" class="form-control" [ngModel]="this.selectedProject!.id"  disabled/>

        </si-form-item>

        <si-form-item label="Parent Project Id" [labelWidth]="labelWidthDummy">
          <input type="text" id="dummyId" class="form-control" placeholder="{{selectedProjectParentId}}"  disabled/>
        </si-form-item>

        <si-form-item label="Description" [labelWidth]="labelWidthDummy">
          <span>{{this.selectedProject!.description}}</span>
      </si-form-item>

      <si-form-item label="Last Access Review" [labelWidth]="labelWidthDummy">
        <span>{{this.projectLastAccessReviewedAt}}</span>
      </si-form-item>

      <si-form-item label="Last Access Review By" [labelWidth]="labelWidthDummy">
        <span>{{this.projectLastAccessReviewedBy}}</span>
      </si-form-item>

      </si-tab>
      <si-tab heading="Owners">
        <div class="p-5" *ngFor="let user of selectedProject!.projectOwners">
          <span class="tag">{{user.email}}</span>
        </div>
      </si-tab>
      <si-tab heading="Members" iconAltText="users">
        <si-accordion [expandFirstPanel]="false">
          <si-collapsible-panel heading="Current Members">
            <div class="p-6">
              <si-loading-spinner *ngIf="isUsersLoading"></si-loading-spinner>
              <div class="mb-4 col-sm-12" *ngIf="!isUsersLoading">
                <div class="position-relative">
                  <i class="search-bar-icon icon element-search text-secondary ng-star-inserted"></i>
                  <input type="text" class="form-control ps-9 icon-start" placeholder="Name or Email"
                    (keyup)="updateUserFilter($event)" autocomplete="off" />
                </div>
              </div>
              <ngx-datatable *ngIf="!isUsersLoading" class="table-element elevation-1"
                [cssClasses]="tableConfig.cssClasses" [rows]="userRows" [rowHeight]="tableConfig.rowHeight"
                [columnMode]="ColumnMode.force" [headerHeight]="tableConfig.headerHeight" [footerHeight]="0"
                [ghostLoadingIndicator]="isUsersLoading" columnMode="force" [selectionType]="selectionType"
                siDatatableInteraction>
                <ngx-datatable-column name="Name" prop='name'>
                </ngx-datatable-column>
                <ngx-datatable-column name="Email" prop='email'>
                </ngx-datatable-column>
                <ngx-datatable-column name="Source" prop='source'>
                </ngx-datatable-column>
                <ngx-datatable-column name="Remove" prop="action" [canAutoResize]="false" [resizeable]="false"
                  [minWidth]="80">
                  <ng-template let-value="value" let-row="row" ngx-datatable-cell-template>
                    <button class="btn btn-circle btn-lg btn-ghost element-delete me-6"
                      (click)='confirmRemoveUser(row.email)'></button>
                  </ng-template>
                </ngx-datatable-column>
                <si-empty-state empty-content icon="element-users"
                  heading="No members assigned to the project"></si-empty-state>
              </ngx-datatable>
            </div>
          </si-collapsible-panel>
          <si-collapsible-panel heading="Assign a User">
            <si-loading-spinner *ngIf="isUsersLoading"></si-loading-spinner>
            <div class="row" *ngIf="!isUsersLoading">
              <div class="col col-sm-12">
                <label for="emailInput" class="form-label">Add a User</label>
                <input type="email" class="form-control" id="emailInput" placeholder="max.mustermann@siemens.com"
                  [(ngModel)]="emailOfUserToAdd" autocomplete="off"><br />
              </div>
              <div class="col col-sm-12 center-items">
                <button type="submit" class="btn btn-primary me-6" (click)="addUserToProject()">Add User To
                  Project</button>
              </div>
            </div>
          </si-collapsible-panel>
        </si-accordion>
      </si-tab>
      <si-tab heading="Subscriptions" *ngIf="!selectedProject.isFolder">

        <div class="projectSubscriptionsTable p-5">
          <div class="mb-4 col-12">
            <div class="position-relative">
              <i class="search-bar-icon icon element-search text-secondary ng-star-inserted"></i>
              <input type="text" class="form-control ps-9 icon-start" placeholder="Name or Subscription ID"
                (keyup)="updateProjectsSubscriptionFilter($event)" autocomplete="off" />
            </div>
          </div>
          <ngx-datatable class="table-element elevation-1"
            [cssClasses]="tableConfig.cssClasses" [rows]="projectSubscriptionRows" [rowHeight]="tableConfig.rowHeight"
            [columns]="projectSubsColumns"
            [columnMode]="ColumnMode.force" [headerHeight]="tableConfig.headerHeight" [footerHeight]="0"
            columnMode="force" [limit]="10" [offset]="projectSubsPageNumber"
            [count]="projectSubscriptionRows.length" [virtualization]="false" [scrollbarV]="false"
            siDatatableInteraction>
            <si-empty-state empty-content icon="element-users"
              heading="No subscriptions available to your user."></si-empty-state>
          </ngx-datatable>
          <si-pagination class="ms-auto mt-8" [currentPage]="projectSubsPageNumber + 1" [totalRowCount]="projectSubscriptionRows.length"
          [pageSize]="10" forwardButtonText="forward" backButtonText="back"
          (currentPageChange)="projectSubsPageNumber = $event - 1">
        </si-pagination>
        </div>
      </si-tab>
    </si-tabset>
  </si-side-panel-content>
</si-side-panel>

<ng-template #template let-modalRef="modalRef">
  <div class="modal-header">
    <h4 *ngIf="!isEditingProject" class="modal-title" id="sample-modal-title">Create Project</h4>
    <h4 *ngIf="isEditingProject" class="modal-title" id="sample-modal-title">Edit Project</h4>
    <button type="button" class="btn btn-circle btn-sm btn-ghost element-cancel" aria-label="Close modal"
      (click)="modalRef.hide()"> </button>
  </div>
  <div class="modal-body">
    <si-loading-spinner *ngIf="areSubscriptionsLoading"></si-loading-spinner>
    <ng-container *ngIf="!areSubscriptionsLoading">
      <form [formGroup]="createProjectForm" class="d-flex h-100">
        <si-form-container #formContainer [form]="createProjectForm" [enableValidationHelp]="false">
          <div si-form-container-content>
            <si-form-item label="Project Name" [labelWidth]="labelWidth" [required]="true">
              <div class="form-control-has-icon w-100">
                <input type="text" id="name" class="form-control" formControlName="name" required minlength="3">
                <i class="invalid-icon element-cancel-filled"></i>
              </div>
            </si-form-item>

            <si-form-item label="Project Description" [labelWidth]="labelWidth" [labelWrap]="false">
              <input type="text" id="description" class="w-100 form-control" formControlName="description">
            </si-form-item>

            <si-form-item label="Business Unit" [labelWidth]="labelWidth" [labelWrap]="false">
              <input type="text" id="businessUnit" class="w-100 form-control" formControlName="businessUnit">
            </si-form-item>

            <si-form-item label="Project Owners" [labelWidth]="labelWidth" [labelWrap]="false">
              <div *ngFor="let email of newProjectOwners">
                <span class="tag">{{email}}
                <button type="button" class="btn btn-circle btn-sm btn-danger element-delete"
                aria-label="delete" (click)="RemoveFromNewOwners(email)"></button>
              </span>
              </div>
              <si-loading-spinner class="small-spinner" *ngIf="isAddingOwner"></si-loading-spinner>
            </si-form-item>

            <si-form-item label="Add a Project Owner" [labelWidth]="labelWidth" [labelWrap]="false">
              <div class="col-9">
              <input type="email" placeholder="max.mustermann@siemens.com" id="newProjectOwner" class="w-100 form-control" formControlName="newProjectOwner">
            </div>
            <div class="col-3 center-div">
              <button type="button" (click)="AddOwnerToProject()" class="btn btn-primary me-2" [disabled]="isAddingOwner">Add</button>
            </div> 
            </si-form-item>
            
            <si-form-item *ngIf="!isEditingProject" label="Set As Folder" [labelWidth]="labelWidth" [labelWrap]="false">
              <input type="checkbox" id="isFolder" formControlName="isFolder" (change)="setFolder()">
            </si-form-item>

            <si-form-item label="Set To Folder" [labelWidth]="labelWidth" [labelWrap]="false">
              <div class="position-relative">
                <si-select [(value)]="selectedFolder" [options]="folderList"></si-select>
              </div>
            </si-form-item>

            <si-form-item *ngIf="!isFolderProject" label="Subscriptions" [labelWidth]="labelWidth" [required]="false" inputId="form-class-label">
              <div class="w-100">
                <div class="mb-4 col-12">
                  <div class="position-relative">
                    <i class="search-bar-icon icon element-search text-secondary ng-star-inserted"></i>
                    <input type="text" class="form-control ps-9 icon-start" placeholder="Name or Subscription ID"
                      (keyup)="updateSubscriptionFilter($event)" autocomplete="off" />
                  </div>
                </div>
                <ngx-datatable class="table-element elevation-1"
                  [cssClasses]="tableConfig.cssClasses" [rows]="subscriptionRows" [rowHeight]="tableConfig.rowHeight"
                  [columns]="subsColumns"
                  [columnMode]="ColumnMode.force" [headerHeight]="tableConfig.headerHeight" [footerHeight]="0"
                  columnMode="force" [selectionType]="subsSelectionType" [limit]="10" [offset]="subsPageNumber"
                  [count]="subscriptionRows.length" [virtualization]="false" [scrollbarV]="false"
                  [selected]="selectedSubscriptions!" [sortType]="SortType.single"
                  siDatatableInteraction>
                  <si-empty-state empty-content icon="element-users"
                    heading="No subscriptions available to your user."></si-empty-state>
                </ngx-datatable>
                <si-pagination class="ms-auto mt-8" [currentPage]="subsPageNumber + 1" [totalRowCount]="subscriptionRows.length"
                [pageSize]="10" forwardButtonText="forward" backButtonText="back"
                (currentPageChange)="subsPageNumber = $event - 1">
              </si-pagination>
              </div>
            </si-form-item>
          </div>
          <div si-form-container-buttons>
            <button *ngIf="!isEditingProject" type="button" (click)="save()" [disabled]="!createProjectForm.valid && isEditingProject"
              class="btn btn-primary me-2">Save</button>
            <button *ngIf="isEditingProject"type="button" (click)="update()" [disabled]="!createProjectForm.valid && !isEditingProject"
              class="btn btn-primary me-2">Save</button>
            <button type="button" (click)="cancel()" class="btn btn-secondary">Cancel</button>
          </div>
        </si-form-container>
      </form>
    </ng-container>

  </div>
</ng-template>